<template>
    <div class="account-box">
      <div class="card-box">
        <el-carousel ref="infoCard" :initial-index="1" trigger="click" :autoplay="false" arrow="never">
          <div class="reset-pwd-form">
            <el-carousel-item>
              <el-form status-icon ref="ruleForm" label-width="120px">
                <el-form-item label="Old Pwd">
                  <el-input style="width: 200px" type="password" v-model="pwd.oldPwd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="New Pwd">
                  <el-input style="width: 200px" type="password" v-model="pwd.newPwd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Confirm">
                  <el-input style="width: 200px" type="password" v-model="pwd.checkPwd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Pass Token">
                  <el-input style="width: 200px" v-model="pwd.pwdToken" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div class="edit-box">
                <el-button @click="cancel">cancel</el-button>
                <el-button type="danger" @click="resetPwd">reset</el-button>
                <el-button type="primary" @click="submitPwd">submit</el-button>
              </div>
            </el-carousel-item>
          </div>
          <el-carousel-item>
            <div class="form-table">
              <div class="info-box">
                <div class="account-name" :style="`background-color: ${color}`">
                  {{ name.substr(0, 1) }}
                </div>
                <p class="fullname">{{ name }}</p>
                <p>{{ introduction }}</p>
              </div>
              <el-divider></el-divider>
              <div class="form-item">
                <label>Password</label>
                <div>
                  <span class="password">{{ hidePwd }}</span>
                  <el-button @click.stop="modifyPwd" size="mini" type="danger" icon="el-icon-edit" circle></el-button>
                </div>
              </div>
              <div class="form-item">
                <label>Integral</label>
                <div>
                  <span class="integral">{{ integral }}</span>
                  <el-button @click.stop="recharge" size="mini" type="primary" icon="el-icon-s-goods" circle></el-button>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="form-item">
                <label>Cache</label>
                <div>
                  <span class="integral">{{ size }}</span>
                  <el-button @click.stop="clearCache" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
                  <el-button @click.stop="downloadLog" size="mini" type="primary" icon="el-icon-download" circle></el-button>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <el-divider content-position="left">Recharge integral</el-divider>
            <div class="integral-box">
              <el-input @input="integralChange" spellcheck="false" v-model="inputRecharge" placeholder="input card number"></el-input>
              <span class="recharge-value">{{ rechargeValue }}</span>
            </div>
            <p v-if="discount && discount != 0" class="coupon-tip">There is a discount card under your account, this recharge will be exchanged for <span class="ponits">{{ Math.floor(this.rechargeValue * 100 * (2 - this.discount)) / 100 }}</span> points</p>
            <div class="edit-box">
              <el-button @click="cancel">cancel</el-button>
              <el-button type="primary" @click="submitRecharge">submit</el-button>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </template>
  <script>
  import { getDiscount } from '@/api/account'
  import { stringToColor } from '@/utils/color'
  import { mapGetters } from 'vuex'
  export default {
    name: 'icon',
    data() {
      return {
        copy: false,
        rechargeValue: 0,
        inputRecharge: '',
        size: 0,
        discount: 0,
        pwd: {
          oldPwd: '',
          checkPwd: '',
          newPwd: '',
          pwdToken: ''
        },
        order: {},
        showRecharge: false
      }
    },
    computed: {
      ...mapGetters([
        'userId',
        'password',
        'name',
        'integral',
        'introduction',
        'accountId'
      ]),
      hidePwd() {
        let str = ''
        for (let i = 0; i < this.password.length; i++) {
          str += '●'
        }
        return str
      },
      color() {
        return stringToColor(this.name)
      }
    },
    created() {
      if (this.userId) {
        this.$store.dispatch('account/getAccount', {
          userId: this.userId
        }).catch(() => {
          this.$message.error('Get account info error.')
        })
      }
      this.size = this.getUseSize()
    },
    methods: {
      getUseSize() {
        var size = 0
        for(const item in localStorage) {
          const value = localStorage.getItem(item)
          if (value) {
            size += value.length
          }
        }
        if (size > 1048576) {
          return  (size / 1048576).toFixed(2) + 'MB'
        } else {
          return  (size / 1024).toFixed(2) + 'KB'
        }
      },
      clearCache() {
        this.$alert('Are you sure you want to delete cache?', 'Tips', {
          confirmButtonText: 'Yes',
          callback: action => {
            if (action === 'confirm') {
              for(const item in localStorage) {
                localStorage.removeItem(item)
              }
              this.size = '0KB'
              this.$message({
                type: 'success',
                message: 'clear cache success.'
              })
            }
          }
        })
      },
      resetPwd() {
        this.pwd.oldPwd = ''
        this.pwd.checkPwd = ''
        this.pwd.newPwd = ''
        this.pwd.pwdToken = ''
      },
      submitPwd() {
        if (this.password !== this.pwd.oldPwd) {
          this.$message.error('old password not correct.')
        } else {
          const { newPwd, oldPwd, checkPwd, pwdToken } = this.pwd
          if (newPwd === checkPwd) {
            if (newPwd !== oldPwd) {
              this.$store.dispatch('user/updatePassowrd', {
                password: newPwd,
                token: pwdToken
              }).then(() => {
                this.$message.success('update password success.')
                setTimeout(() => {
                  this.$store.dispatch('user/logout')
                  this.$router.push('/login')
                }, 1000)
              }).catch((res) => {
                this.$message.error(res.message)
              })
            } else {
              this.$message.error('new password can not eq new pwd.')
            }
          } else {
            this.$message.error('confirm password and new pwd not eq.')
          }
        }
      },
      integralChange(value) {
        if (value) {
          const lines = value.split('_')
          if (lines.length === 2) {
            this.rechargeValue = lines[0]
          }
        }
      },
      copyAddress() {
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, 1000);
        this.$message.info('copy success.')
      },
      downloadLog() {
        // 1. 获取 localStorage 中的所有数据
        const logs = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const content = localStorage.getItem(key);
            logs[key] = content.replaceAll('"', "'")
        }

        // 2. 将数据转换为 JSON 字符串
        const logsJson = JSON.stringify(logs, null, 2);  // 格式化输出，便于查看

        // 3. 创建 Blob 对象
        const blob = new Blob([logsJson], { type: 'application/json' });

        // 4. 创建下载链接
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'logs.json';

        // 5. 自动点击链接进行下载
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      submitRecharge() {
        if (this.rechargeValue) {
          this.$store.dispatch('account/updateAccount', this.inputRecharge)
            .then((response) => {
              const { integral } = response.data
              this.$message.success(`recharge ${integral} success.`)
              this.cancel()
            }).catch( msg => {
              this.$message.info(msg)
            })
        }
      },
      cancel() {
        this.rechargeValue = 0
        this.inputRecharge = ''
        this.selectIntegral = -1
        this.$refs.infoCard.setActiveItem(1)
      },
      rechargeIntegral() {
        this.showRecharge = true
      },
      modifyPwd() {
        this.$refs.infoCard.setActiveItem(0)
      },
      recharge() {
        this.$refs.infoCard.setActiveItem(2)
        getDiscount(this.accountId).then(res => {
          this.discount = res.data.discount
        })
      }
    }
  }
  </script>
  <style rel="stylesheet/scss" lang="scss">
  .account-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .coupon-tip {
      font-size: 12px;
      text-align: left;
      padding: 10px 20px;
      .ponits {
        color: #fc5615;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .info-box {
      .fullname {
        font-weight: bold;
      }
      .account-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        font-size: 48px;
        color: #FFF;
        margin: 0 auto;
      }
    }
    .reset-pwd-form {
      .el-form {
        margin-top: 20px;
      }
      .el-input__inner {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .integral-box {
      margin-top:  40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      .recharge-value {
        width: 80px;
        text-align: center;
      }
    }
    .edit-box {
      padding: 10px 20px;
      display: flex;
      button {
        flex: 1;
      }
    }
    .order-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: rgba(11, 11, 11, 0.022);
      .tip {
        font-size: 12px;
        color: rgb(171, 171, 171);
      }
      .amount-line {
        font-size: 12px;
        .amount-number {
          font-weight: bold;
          font-size: 16px;
          margin: 10px 4px;
        }
        .char-about {
          margin: 0 10px;
        }
      }
      .count-down {
        margin: 10px;
        text-align: center;
        .time-number {
          font-weight: bold;
        }
        .time-line {
          margin: 0 auto;
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }
      .order-line {
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 16px;
        font-weight: bold;
        color: black;
        justify-content: space-around;
        .order-text {
          margin: 0 10px;
        }
      }
      .address-line {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
      .address-line, .order-line {
        padding: 0 10px;
        p {
          margin: 4px 0;
        }
        i {
          cursor: pointer;
          margin-left: 10px;
        }
        .el-icon-check {
          color: rgb(0, 152, 0);
        }
      }
      .btn-box {
        margin: 10px 0;
      }
      .img-box-wrapper {
        background-color: #FFF;
        padding: 10px;
        .img-box {
          display: inline-block;
          padding: 10px;
          margin: 10px auto;
          border: 1px solid #f2f2f2;
          background-color: #FFF;
          .wallet-img {
            width: 200px;
          }
        }
      }
      
    }
    .form-table {
      padding: 10px;
      .el-form-item__content {
        display: flex;
      }
      .form-item {
        display: flex;
        font-size: 16px;
        align-items: center;
        margin: 10px;
        label {
          font-weight: bold;
          width: 100px;
          text-align: left;
        }
        div {
          flex: 1;
          text-align: left;
          display: flex;
          align-items: center;
        }
        .integral {
          font-size: 20px;
          font-weight: bold;
          color: rgb(255, 121, 26);
          margin-right: 10px;
        }
        .password {
          margin-right: 10px;
        }
      }
    }
    .card-box {
      flex: 1;
      .el-carousel__container, .el-carousel {
        height: 100%;
      }
    }
  }
  </style>
    